import { handleActions } from "redux-actions";
import {
  Reconciliation,
  Reconciliation as IReconciliationItem
} from "./interface";
import { action, PayloadAction } from "typesafe-actions";
import produce from "immer";
import createAsyncSagaAction from "../../cores/createAsyncSagaAction";
import axios, { AxiosResponse } from "axios";
import { createAsyncSagaReducerMap } from "../../cores/createAsyncSagaReducerMap";

interface ReconciliationState {
  reconciliation: Reconciliation | null;
  resultFiles: File | null;
  progressLabel: string;
}

export enum ReconciliationTypes {
  getReconciliationItems = "@reconciliation/getReconciliationItems",
  truncateReconciliationItems = "@reconciliation/getReconciliationItems",
  setReconciliationMemo = "@reconciliation/setReconciliationMemo",
  getResultFiles = "@reconciliation/getResultFiles",
  truncateResultFiles = "@reconciliation/truncateResultFiles",
  mapReconciliation = "@reconciliation/mapReconciliation",
  syncReconciliation = "@reconciliation/syncReconciliation",
  setProgressLabel = "@reconciliation/setProgressLabel",
  truncateProgressLabel = "@reconciliation/truncateProgressLabel"
}

const initialState: ReconciliationState = {
  reconciliation: null,
  resultFiles: null,
  progressLabel: ""
};

export const ReconciliationActions = {
  getReconciliationItems: createAsyncSagaAction(
    ReconciliationTypes.getReconciliationItems,
    (month: string) => {
      return axios.get(`result/?month=${month}`);
    }
  ),
  truncateReconciliationItems: () =>
    action(ReconciliationTypes.truncateReconciliationItems),
  setReconciliationMemo: createAsyncSagaAction(
    ReconciliationTypes.setReconciliationMemo,
    ({
      key,
      car,
      content,
      category
    }: {
      car?: number;
      content?: string;
      key?: string;
      category?: string;
    }) => {
      return axios.post(`memo/`, {
        car,
        content,
        expenditure_evidence: key,
        category
      });
    }
  ),
  getResultFiles: createAsyncSagaAction(
    ReconciliationTypes.getResultFiles,
    (month: string) => {
      return axios.post(`result/files/`, { month });
    }
  ),
  truncateResultFiles: () => action(ReconciliationTypes.truncateResultFiles),
  mapReconciliation: createAsyncSagaAction(
    ReconciliationTypes.mapReconciliation,
    (carId: number, expenditureEvidenceKey: string) => {
      return axios.post(`map/`, {
        car: carId,
        expenditure_evidence: expenditureEvidenceKey
      });
    }
  ),
  syncReconciliation: createAsyncSagaAction(
    ReconciliationTypes.syncReconciliation,
    (month: string) => axios.post(`sync/`, { month })
  ),
  setProgressLabel: (message: string) => action(ReconciliationTypes.setProgressLabel, message),
  truncateProgressLabel: () => action(ReconciliationTypes.truncateProgressLabel)
};

export default handleActions<ReconciliationState, any>(
  {
    ...createAsyncSagaReducerMap(ReconciliationTypes.getReconciliationItems, {
      onSuccess: (
        state,
        action: PayloadAction<string, AxiosResponse<IReconciliationItem>>
      ) => {
        return produce(state, draft => {
          draft.reconciliation = action.payload.data;
        });
      }
    }),
    [ReconciliationTypes.truncateReconciliationItems]: state => {
      return produce(state, draft => {
        draft.reconciliation = null;
      });
    },
    ...createAsyncSagaReducerMap(ReconciliationTypes.getResultFiles, {
      onSuccess: (
        state,
        action: PayloadAction<string, AxiosResponse<File>>
      ) => {
        return produce(state, draft => {
          draft.resultFiles = action.payload.data;
        });
      }
    }),
    [ReconciliationTypes.truncateResultFiles]: state => {
      return produce(state, draft => {
        draft.resultFiles = null;
      });
    },
    [ReconciliationTypes.setProgressLabel]: (
      state,
      action: PayloadAction<string, string>
    ) => {
      return produce(state, draft => {
        draft.progressLabel = action.payload;
      });
    },
    [ReconciliationTypes.truncateProgressLabel]: state => {
      return produce(state, draft => {
        draft.progressLabel = "";
      });
    }
  },
  initialState
);
