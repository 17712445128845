import React from "react";
import styles from "./Header.module.scss";
import MonthPicker from "../MonthPicker/MonthPicker";
import { useRouter } from "../../cores/useRouter";
import { useDispatch } from "react-redux";
import { ReconciliationActions } from "../../store/reconciliation";
import ProgressLabel from "../ProgressLabel/ProgressLabel";
import { ReactComponent as RefreshIcon } from "../../images/ic-refresh.svg";
import { deleteCookieToken } from "../../cores/deleteCookieToken";
import Ink from "react-ink";

const Header: React.FC<{}> = () => {
  const { query } = useRouter();
  const month = query.get("month");
  const dispatch = useDispatch();
  const onClickSyncButton = () => {
    if (month) {
      dispatch(ReconciliationActions.syncReconciliation(month));
    }
  };
  const onClickLogout = () => {
    deleteCookieToken();
  };
  return (
    <div className={styles.header}>
      {month && <MonthPicker month={month} />}
      <div className={styles.right}>
        <ProgressLabel />
        <div className={styles.button} onClick={onClickSyncButton}>
          <RefreshIcon className={styles.refreshIcon} />
          동기화
          <Ink />
        </div>
        <div className={styles.button} onClick={onClickLogout}>
          로그아웃
          <Ink />
        </div>
      </div>
    </div>
  );
};

export default Header;
